.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.03);
  font-size: 25px;
  font-weight: bold;
  margin: 20px 0;
  height: 75px;
  width: 200px;
  box-shadow: 0px 0 10px 0 rgba(0, 0, 0, 0.1);
  border: none;
  cursor: pointer;
}

.button:hover {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.05);
}

.button:active {
  box-shadow: 0px 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.01);
}
