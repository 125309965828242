.tool-bar {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fafafa;
  box-shadow: 0px 0 10px 0 rgba(0, 0, 0, 0.09);
  color: black;
  width: 100vw;
  height: 50px;
}

div.nav-spacer {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

span.tool-bar__app-name {
  font-size: 30px;
  font-weight: bold;
  margin: 0 0 0 20px;
}

.tool-bar__nav {
  display: none;
}

.tool-bar__nav ul {
  display: flex;
  list-style: none;
}

.tool-bar a {
  cursor: pointer;
  display: flex;
  align-content: center;
}

.tool-bar__nav li {
  padding: 0 5px;
}

.app-logo-toolbar_landing-page {
  height: 30px;
  margin: 0;
}

button.tool-bar_about-button {
  height: 30px;
  width: 100px;
  box-shadow: 0px 0 10px 0 rgba(0, 0, 0, 0.2);
}

.tool-bar_about-button-text {
  font-size: 20px;
}

@media screen and (min-width: 700px) {
  span.tool-bar__app-name {
    margin: 0 10px;
  }

  .tool-bar__nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 10px;
  }

  div.nav-spacer {
    width: 220px;
    margin: 0;
  }
}
