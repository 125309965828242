* {
  box-sizing: border-box;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}
html,
body {
  height: 100%;
  width: 100vw;
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 100vw;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
}
