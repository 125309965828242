.nav-button_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.nav-button_dynamic-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.nav-button_icon {
  width: 100px;
  height: 100px;
}
