.breathe-timer {
  opacity: 0;
  z-index: 1;
}

.breathe-timer_black {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.95);
  z-index: 0;
}

.breathe-timer_white {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  background: rgb(255, 255, 255);
  z-index: 0;
}

.breathe-timer_animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: whitesmoke;
  animation: breathe 6.5s linear infinite;
  z-index: 1;
}

@keyframes breathe {
  0% {
    width: 5px;
    height: 5px;
    box-shadow: 0 0 0 25px rgba(255, 255, 255, 0.1),
      0 0 0 50px rgba(255, 255, 255, 0.1), 0 0 0 75px rgba(255, 255, 255, 0.1),
      0 0 0 100px rgba(255, 255, 255, 0.1), 0 0 0 125px rgba(255, 255, 255, 0.1);
  }
  50% {
    width: 250px;
    height: 250px;
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.1),
      0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 30px rgba(255, 255, 255, 0.1),
      0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 50px rgba(255, 255, 255, 0.1);
  }
  100% {
    width: 5px;
    height: 5px;
    box-shadow: 0 0 0 25px rgba(255, 255, 255, 0.1),
      0 0 0 50px rgba(255, 255, 255, 0.1), 0 0 0 75px rgba(255, 255, 255, 0.1),
      0 0 0 100px rgba(255, 255, 255, 0.1), 0 0 0 125px rgba(255, 255, 255, 0.1);
  }
}

.breathe-timer_exit-button {
  margin: 80vh auto 0 auto;
  color: rgb(0, 0, 0);
  background-color: rgba(255, 255, 255, 0.342);
  z-index: 1;
}

.breathe-timer_exit-button:hover {
  background-color: rgb(255, 255, 255);
}

.breathe-timer_fade-in {
  transition: opacity 1.5s linear;
  -webkit-transition: opacity 1.5s linear;
  -moz-transition: opacity 1.5s linear;
  -ms-transition: opacity 1.5s linear;
  -o-transition: opacity 1.5s linear;
  opacity: 1;
}
