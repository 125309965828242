.toggle-button {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  min-width: 30px;
  margin: 0 0 0 20px;
}

.toggle-button:focus {
  outline: none;
}

div.toggle-button__line {
  width: 30px;
  height: 4px;
  background-color: black;
  border-radius: 1px;
}

@media screen and (max-width: 698px) {
  .toggle-button {
    display: flex;
  }
}
