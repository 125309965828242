p.quote-text {
  margin: 30px 0 0 0;
  max-width: 600px;
}

.quote-container {
  padding: 0 10px 0 10px;
}

.color_section {
  justify-content: space-evenly;
}

section.explain-color_section {
  min-height: 100vh;
}

div.explain-color_div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  max-width: 666px;
  height: 100%;
  padding: 0 20px;
}

h2.explain-color_h2 {
  font-size: 28px;
  font-weight: bold;
  margin: 0 0 20px 0;
}

p.quote-author {
  margin: 10px 20px 0 0;
  font-weight: bold;
}

.breathe-page_button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.breathe-page_button-container p {
  margin-bottom: px;
}

.color-chooser_container {
  margin-top: 20px;
}
