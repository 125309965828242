.header_landing-page {
  display: flex;
  justify-content: start;
  width: 100vw;
  height: 100vh;
  margin: 0;
}

.container_header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

div.container_header {
  width: 100vw;
  margin: 50px 0 0 0;
}

.app-name {
  letter-spacing: -0.1em;
  font-size: 70px;
  font-weight: 800;
  margin: 10px 0;
}

.tagline {
  font-size: 20px;
  width: 315px;
}

.tagline_underline {
  text-decoration: underline;
}

.app-logo_landing-page {
  max-height: 40vh;
}

.landing-page-icon {
  height: 130px;
  width: 130px;
}

.down-arrow_container_landing-page {
  display: flex;
  position: fixed;
  flex-direction: row;
  bottom: 20px;
  justify-content: flex-end;
  align-items: center;
  height: 70px;
  width: 100vw;
}

.down-arrow_landing-page {
  margin: 0 20px 0 10px;
  height: 50px;
  width: 50px;
  border-radius: 40px;
  box-shadow: 0px 0 10px 0 rgba(0, 0, 0, 0.09);
}

.down-arrow_landing-page:hover {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}

.down-arrow_landing-page:active {
  box-shadow: 0px 0 10px 0 rgba(0, 0, 0, 0.09);
}

.warning_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #73020bc4;
  border-radius: 5px;
  padding: 0 30px 30px 30px;
  max-width: 550px;
  box-shadow: 0 5px 10px 5px rgb(0, 0, 0);
}

@media screen and (min-width: 700px) {
  .app-name {
    font-size: 100px;
    margin: -10px 0 0 0;
  }

  .down-arrow_landing-page {
    margin: 0 30px 0 10px;
    height: 60px;
    width: 60px;
  }
}

@media screen and (min-width: 1200px) {
  .app-name {
    font-size: 150px;
    margin: -50px 0 -20px 0;
  }

  .down-arrow_landing-page {
    margin: 0 50px 0 10px;
    height: 70px;
    width: 70px;
  }
}
