.side-drawer {
  position: fixed;
  height: 100vh;
  background: #0d0d0d;
  box-shadow: 3px 0 7px rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 200px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.25s ease-out;
}

.side-drawer.open {
  transform: translateX(0);
}

.side-drawer img {
  width: 100%;
}

.side-drawer ul {
  flex-direction: column;
  justify-content: center;
  list-style: none;
  display: flex;
  padding: 0;
}

.side-drawer a {
  font-size: 30px;
  font-weight: bold;
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.side-drawer li {
  margin: 20px 0;
}

img.side-drawer_logo {
  margin: 40px 0 0 0;
  width: 100px;
  height: 100px;
}
