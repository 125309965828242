div#root {
  height: 100%;
}

div.content {
  flex: 1 0 auto; /* Prevent Chrome, Opera, and Safari from letting
  these items shrink to smaller than their content's default minimum size. */
  /* position: relative;
  z-index: 1; */
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 0 20px;
  background-color: transparent;
  margin-bottom: 20px;
}

div {
  display: flex;
  flex-direction: column;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

textarea {
  min-width: 300px;
  text-align: left;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 0px 0 10px 0 rgba(0, 0, 0, 0.09);
  margin: 10px 0 0 0;
}

textarea:hover {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

label {
  font-size: 20px;
}

select {
  height: 50px;
  width: 200px;
  font-size: 20px;
  border-style: none;
  padding: 0 15px;
  margin: 10px 0 0 0;
}

button {
  padding: 10px;
  margin: 5px;
  min-width: 100px;
}

h1,
h2,
h3 {
  font-family: "Manrope", sans-serif;
  font-weight: bolder;
}

h1 {
  font-size: 45px;
  text-align: center;
  letter-spacing: 5px;
  margin: 30px 0;
}

h2 {
  font-size: 30px;
  letter-spacing: 2px;
}

h3 {
  font-size: 26px;
}

p {
  text-align: center;
  max-width: 500px;
}

.xtra-small-text {
  font-size: 18px;
}

.small-text {
  font-size: 20px;
}

.medium-text {
  font-size: 25px;
}

.container_dark {
  background-color: #0d0d0d;
  color: white;
  box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.1);
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.space-evenly {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.space-together {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section_margin {
  margin: 0 10px;
}

.div-container_eighty-vh {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 50px;
  min-height: 100vh;
}

.drop-shadow {
  box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.1);
}

.flatten {
  height: 0;
  margin: 0;
  padding: 0;
}

.transparent {
  color: transparent;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: 500ms ease;
}

img.icon {
  height: 130px;
  width: 130px;
}

@media screen and (min-width: 700px) {
  .div-container_eighty-vh {
    width: 90%;
  }
}
